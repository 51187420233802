const en = {
    liuyan:{
        "请输入姓名": "Заполните ФИО",
        "请输入邮箱": "Электронная почта",
        "请输入内容": "Введите содержимое",
        "留言成功":"Отправлено",
    },
    index:{
        "img1":require('@/assets/index/ru_1.png'),
        "img2":require('@/assets/index/ru_2.png'),
        "img3":require('@/assets/index/ru_3.png'),
        "img4":require('@/assets/index/ru_4.png'),
        "img5":require('@/assets/index/ru_5.png'),
        "img6":require('@/assets/index/ru_6.png'),
        "img7":require('@/assets/index/ru_7.png'),
        "img8":require('@/assets/index/ru_8.png'),
        "img9":require('@/assets/index/ru_9.png'),
        "img10":require('@/assets/index/ru_10.png'),
        "img11":require('@/assets/index/ru_11.png'),
        "img12":require('@/assets/index/ru_12.png'),
        "img13":require('@/assets/index/ru_13.png'),
        "img14":require('@/assets/index/ru_14.png'),
        "img15":require('@/assets/index/ru_15.png'),
        "img16":require('@/assets/index/ru_16.png'),
        "img17":require('@/assets/index/ru_17.png'),
        "img18":require('@/assets/index/ru_18.png'),
        "img19":require('@/assets/index/ru_19.png'),
        "img20":require('@/assets/index/ru_20.png'),
        "img21":require('@/assets/index/ru_21.png'),
        "img22":require('@/assets/index/ru_22.png'),
        "img23":require('@/assets/index/ru_23.png'),
        "img24":require('@/assets/index/ru_24.png'),
        "img25":require('@/assets/index/ru_25.png'),
        "img26":require('@/assets/index/ru_26.png'),
        "img27":require('@/assets/index/ru_27.png'),
        "img28":require('@/assets/index/ru_28.png'),
        "img29":require('@/assets/index/ru_29.png'),
        "img30":require('@/assets/index/ru_30.png'),
        "img31":require('@/assets/index/ru_31.png'),
        "img32":require('@/assets/index/ru_32.png'),
        "img33":require('@/assets/index/ru_33.png'),
        "img34":require('@/assets/index/ru_34.png'),
        "img35":require('@/assets/index/ru_35.png'),
        "img36":require('@/assets/index/ru_36.png'),
        "img37":require('@/assets/index/ru_37.png'),
        "img38":require('@/assets/index/ru_38.png'),
        "img39":require('@/assets/index/ru_39.png'),
        "img40":require('@/assets/index/ru_40.png'),
        "img41":require('@/assets/index/ru_41.png'),
        "img42":require('@/assets/index/ru_42.png'),
        "img43":require('@/assets/index/ru_43.png'),
        "img44":require('@/assets/index/ru_44.png'),
        "img45":require('@/assets/index/ru_45.png'),
    },
    yunshu1: {
        "img1": require('@/assets/yunshu/ru_1.png'),
        "img2": require('@/assets/yunshu/ru_2.png'),
        "img3": require('@/assets/yunshu/ru_3.png'),
        "img4": require('@/assets/yunshu/ru_4.png'),
        "img5": require('@/assets/yunshu/ru_5.png'),
        "img6": require('@/assets/yunshu/ru_6.png'),
        "title1": "Мы перевозим.",
        "title2": "Основная идея",
        "title3": "Индивидуальная логистика, отслеживание в режиме реального времени и экспертная поддержка всесторонне повышают безопасность и эффективность перевозок.",
        "title4": "Автоперевозки",
        "title5": "Судовые перевозки",
        "title6": "Перевозка на самолете",
        "title7": "железнодорожный транспорт",
        "name1": "Логистика эффективна, и товары будут доставлены в целости и",
        "name2": "Индивидуальные логистические решения обеспечат безопасную и своевременную доставку грузов.",
        "name3": "Отслеживание в реальном времени и динамика транспортировки",
        "name4": "Для улучшения восприятия транспорта осуществляется отслеживание его хода в режиме реального времени.",
        "name5": "Профессиональная команда поможет оптимизировать",
        "name6": "Команда специалистов оптимизирует процессы транспортировки и повышает конкурентоспособность на рынке.",
        "name7": "Наше преимущество",
        "name8": "Присоединяйтесь к нам",
        "name9": "ЛoдИ, Ицyциe TpaHспOpT",
        "name10": "Видение компании",
        "name11": "Независимые участники склада открыты",
    },
    caigou: {
        "img1": require('@/assets/caigou/ru_1.png'),
        "img2": require('@/assets/caigou/ru_2.png'),
        "img3": require('@/assets/caigou/ru_3.png'),
        "img4": require('@/assets/caigou/ru_4.png'),
        "img5": require('@/assets/caigou/ru_5.png'),
        "img6": require('@/assets/caigou/ru_6.png'),
        "img7": require('@/assets/caigou/ru_7.png'),
        "img8": require('@/assets/caigou/ru_8.png'),
    },
    daohang: {
        "title1": "первая страница",
        "title2": "бизнес",
        "title3": "Случай",
        "title4": "о нас",
        "title5": "Вход",
        "title6": "Внешняя торговля такая же простая,как и внутренняя",
        "name1": "Крест -грузовой перевод",
        "name2": "транспорт",
        "name3": "поставщик",
        "name4": "Поставщик услуг",
        "name5": "Покупатель",
        "name6": "Независимый склад",
        "name7": "покидать",
    },
    guanyu: {
        "name1": "ПолHЫЙ охваT трансграниЧHоЙ TOPГОBЛИ",
        "name2": "справедливые и ЧесTHЫе TOрГИ",
        "name3": "Обмен данныMи Иинформацией",
        "name4": "гарантированнаЯ послепродаЖнаЯ оплата",
        "title1": "Ведущая компания в зоне свободной торговли Китая",
        "title2": "Официальный партнер промышленного союза провинции Ляонин (Китай)",
        "title3":"Ключевая компания по развитию бизнеса в Министерствекоммерции Внутренней Монголии (Китай)",
        "title4":"Ведущая компания в зоне свободной торговли Ха́рбин (Китай)",
        "title5":"Пилотная компания по развитию платформ международной торговли в Китае",
        "title6":"Компания, выбранная правительством города Сипин(Китай) для сотрудничества вэкспорте",

        "img1":require('@/assets/guanyu/ru_1.png'),

        "img2":require('@/assets/guanyu/ru_2.png'),
        "img3":require('@/assets/guanyu/ru_3.png'),
        "img4":require('@/assets/guanyu/ru_4.png'),
        "img5":require('@/assets/guanyu/ru_5.png'),
        "img6": require('@/assets/guanyu/ru_6.png'),
        "img7": require('@/assets/guanyu/ru_7.png'),
        "img8": require('@/assets/guanyu/ru_8.png'),
        "img9": require('@/assets/guanyu/ru_9.png'),
        "img10" :require('@/assets/guanyu/ru_10.png'),
        "img11" :require('@/assets/guanyu/ru_11.png'),
        "img12" :require('@/assets/guanyu/ru_12.png'),
        "img13" :require('@/assets/guanyu/ru_13.png'),
        "img14" :require('@/assets/guanyu/ru_14.png'),
        "img15": require('@/assets/guanyu/ru_15.png'),
        "img16": require('@/assets/guanyu/ru_16.png'),
        "img17": require('@/assets/guanyu/ru_17.png'),
        "img18": require('@/assets/guanyu/ru_18.png'),
        "img19": require('@/assets/guanyu/ru_19.png'),
        "img20": require('@/assets/guanyu/ru_20.png'),
        "img21": require('@/assets/guanyu/ru_21.png'),
        "img22": require('@/assets/guanyu/ru_22.png'),
        "img23": require('@/assets/guanyu/ru_23.png'),
        "img24": require('@/assets/guanyu/ru_24.png'),
        "img25": require('@/assets/guanyu/ru_25.png'),
        "img26": require('@/assets/guanyu/ru_26.png'),
        "img27": require('@/assets/guanyu/ru_27.png'),
        "img28": require('@/assets/guanyu/ru_28.png'),
        "img29": require('@/assets/guanyu/ru_29.png'),
        "img30": require('@/assets/guanyu/ru_30.png'),
        "img31": require('@/assets/guanyu/ru_31.png'),
        "img32": require('@/assets/guanyu/ru_32.png'),
        "img34": require('@/assets/guanyu/ru_34.png'),
        "img35": require('@/assets/guanyu/ru_35.png'),
        "img36": require('@/assets/guanyu/ru_36.png'),
        "img37": require('@/assets/guanyu/ru_37.png'),
        "img38": require('@/assets/guanyu/ru_38.png'),
        "img39": require('@/assets/guanyu/ru_39.png'),
        "img40": require('@/assets/guanyu/ru_40.png'),
        "img41": require('@/assets/guanyu/ru_41.png'),
        "img42": require('@/assets/guanyu/ru_42.png'),
        "img43": require('@/assets/guanyu/ru_43.png'),
        "img44": require('@/assets/guanyu/ru_44.png'),
        "img45": require('@/assets/guanyu/ru_45.png'),
    }, 
    anli:{ 
        "name":" Введение в дело",
        "detail" :"Отличное содержание кейса",
        "img":require('@/assets/anli/zh.png'),
        "img1":require('@/assets/anli/ru_1.png'),
        "img2":require('@/assets/anli/ru_2.png'),
        "img3": require('@/assets/anli/ru_3.png'),
        "img4":require('@/assets/anli/ru_4.png'),
        "img5" :require('@/assets/anli/ru_5.png'),
        "img6": require('@/assets/anli/ru_6.png'),
        "img7": require('@/assets/anli/ru_7.png'),
        "img8": require('@/assets/anli/ru_8.png'),
        "img9": require('@/assets/anli/ru_9.png'),
        "img10" :require('@/assets/anli/ru_10.png'),
        "img11" :require('@/assets/anli/ru_11.png'),
    }, 
    "name": "Ва ше ФИО",
    "mobile": " Ваше адрес электронной почты/Номер телефона",
    "content": " Подробности",
    "submit": "П одтвержденная отправка",
    "qname": "Введите ФИО",
    "qmobile": "Пожалуйста, введите номер вашего телефона",
    "qcontent": "Введите содержимое",
    "download": "Нажмите, чтобы скачать.",
    yunshu: {
        "运输路线": "транспортные маршруты",
        "运输方式": "Способ транспортировки",
        "货物类型": "Тип товаров",
        "请选择": "Выбрать город",
    "搜索": "поиск",
    "中国": "Китай",
    "俄罗斯": "Россия",
    "国籍": "Гражданство",
    "国籍 + 图标": "Транспортная компания",
    "运输公司": "транспортные маршруты",
    "运输路线": "Откуда",
    "出发城市": "Город назначения",
    "目的城市": "вид транспорта",
    "运输方式": "Подробности перевозки",
},
    login:{
        "title": "Минимальное количество партии(шт)",
        "text": "Профессиональная команда",
        "text2":"",
        "text1": "GLUZ Различная приграничная торговля",
        "登录":"Авторизоваться",
        "账号":"Логин",
        "密码":"Пароль",
        "请输入手机号":"Введите номер телефона или почту",
        "请输入密码":"Введите пароль",
        "切换语言":"Переключить язык",
    },
    huodai: {
        "数据统计": "Статистика данных",
        "查询": "Поиск",
        "运输方式": "Способ транспортировки",
        "货物类型": "Тип товаров",
        "通关服务": "Услуги таможенного",
        "搜索": "поиск ",
        "报关": "Таможенная декларация",
        "清关": "Таможенная очистка",
        "报关+清关": "Таможенная декларация + очистка",
        "列表": "Таблица",
        "请选择": "Выбрать город",
        "中国": "Китай",
        "俄罗斯": "Россия",
        "国籍": "Гражданство",
        "通关口岸": "Таможенный переход",
        "操作": "Управление",
        "聊聊": "общаться",
        "看看": "Взгляните",
        "汽运": "Автотранспорт",
        "空运": "Авиадоставка",
        "海运": "Морская доставка",
        "火车": "Ж/Д доставка",
        "不限": "Без ограничений",
        "联系客服": "Свяжитесь со сотрудниками клиентского сервиса.",
        "咨询客服可以解决你一切问题": "Пожалуйста, обратитесь в службу поддержки клиентов, может решить все ваши проблемы",
        "咨询客服": "Обратитесь в службу поддержки клиентов",
        "运输公司": "Транспортная компания",
        "运输路线": "транспортные маршруты",
        "出发城市": "Откуда",
        "目的城市": "вид транспорта",
        "运输方式": "вид транспорта",
        "所在国家": "Страна",
        "所属国籍": "Национальность",
        "发布人": "Опубликовано",
        "任务名称": "Название задачи",
        "任务要求": "Требования",
        "任务时长": "Продолжительность задачи",
        "工作内容": "Описание работы",
        "商品名称": "название товара",
        "商品类型": "Категория",
        "商品分类": "категорию",
        "采购商名称": "Имя закупщика",
        "地址": "адрес",
        "采购数量": "Список товаров",
        "商品介绍": "Описание товара",
        "商品图片": "Изображения товаров",
        "请输入": "Введите",
        "中国货物": "КИТАЙСКИЕ ТОВАРЫ",
        "俄罗斯货物": "РУССКИЕ ТОВАРЫ",
        "在俄中国货物": "Кит. товары в России",
        "在俄中货": "Кит. товары в России",
        "在中俄罗斯货物": "Русские товары в Китае",
        "在中俄货": "Русские товары в Китае",

"我们的优势":"Возможности экспедирования грузов",
"服务城市数量":"Служение городу",
"专业代办人员数量":"Персонал  услуг",
"服务好评":"Хорошие отзывы",
"发布任务列表":"Список людей, которым нужна помощь",
"覆盖城市数量":"Охваченные города",
"覆盖口岸数量":"Крытые таможенные",
"服务商数量":"Поставщик услуг",
"跨境货代订单":"Список людей, которым нужна помощь",
"公司愿景":"Видение компании",
"我们的优势":"Возможности экспедирования грузов",
"提升品牌影响力，拓展俄罗斯市场":"Усиление влияния бренда и расширение рынка в России.",
"高效管理交易流程":"Эффективное управление процессом сделки",
"提升市场竞争力":"Повышение конкурентоспособности рынка.",
"采购订单":"3aЯвкa нaпoиск Toваpa(ToprИ)",
"国籍 采购商名称":"Название компании",


    },
    item: {
        "goods_brief":"goods_brief_ru",
        "mode":"mode_ru",
        "car_category_id_text": "car_category_id_text_ru",
        "msg": "msg_ru",
        "profile": "profile_ru",
        "title": "titl e_ru",
        "load_n um": "load_num_ru",
        "name": "name_ru",
        "brand": "brand_ru",
        "goods_num": "goods_num_ru",
        "goods_weight": "goods_weight_ru",
        "product_weight": "product_weight_ru",
        "goods_volume": "goods_volume_ru",
        "product_volume": "product_volume_ru",
        "show_text": "show_text_ru",
        "mode_value": "value_ru",
        "person_name": "person_name_ru",
        "label": "label_ru",
        "car_l": "car_l_ru",
        "car_w": "car_w_ru",
        "car_h": "car_h_ru",
        "load_num": "load_num_ru",
        "company_name": "company_name_ru",
        "industry": "industry_ru",
        "category_name": "category_name_ru",
        "skill": "skill_ru",
        "content": "content_ru",
        "desc": "desc_ru",
        "introduction": "introduction_ru",
        "con": "con_ru",
        "con_text": "con_text_ru",
        "speed_ranking": "speed_ranking_ru",
        "price_ranking": "price_ranking_ru",
        "num_ranking": "num_ranking_ru",
        "show_is_topping": "show_is_topping_ru",
        "main_product": "main_product_ru",
        "goods_name": "goods_name_ru",
        "product": "product_ru",
        "username": "username_ru",
        "transport_name": "transport_name_ru",
        "rating": "rating",
        "mobile": "mobile",
        "country_name": "country_name",
        "email": "email",
        "idnum": "idnum",
        "passport_code": "passport_code",
        "passport_image": "passport_image",
        "product_image": "product_image",
        "business_image": "business_image",
        "license_image": "license_image",
        "shop_image": "shop_image",
        "show_goods_num": "show_goods_num_ru",
        "number_unit": "number_unit_ru",
        "price_unit": "price_unit_ru",
        "whole_diffuse": "whole_diffuse_ru",
        "text": "text_ru",
        "car_category_name": "car_category_name_ru",
        "text": "text_ru",
        "product_address": "product_address_ru",
        "product_address_details": "product_address_details_ru",
        "reach_address_details": "reach_address_details_ru",
        "scope_category": "scope_category_ru",
        "user": {
            "username": "username_ru",
            "identity_name": "identity_name_ru"
        },
        "city": {
            "mergename": "mergename_ru",
            "name": "name_ru",
            "shortname": "shortname_ru"
        },
        "reach_address": {
            "mergename": "mergename_ru",
            "name": "name_ru",
            "shortname": "shortname_ru"
        },
        "product_address": {
            "mergename": "mergename_ru",
            "name": "name_ru",
            "shortname": "shortname_ru"
        }
    },
}
export default en;