<!-- GlobalOverlay.vue -->
<template>
    <div @click="handeleisShow" v-if="visible" class="global-overlay" >
      <div class="overlay-content" >
        <span class="mmd">{{ text }}</span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        visible: false,
        text: ''
      };
    },
    methods: {
        handeleisShow(){
            this.hide()
        },
      show(text) {
        this.text = text;
        this.visible = true;
      },
      hide() {
        this.visible = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .mmd{
    font-size: 30px;
    font-weight: bold;
    color: #FA7623;
  }
  .global-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  
  .overlay-content {
    color: white;
    text-align: center;
  }
  </style>
  