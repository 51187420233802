import Vue from "vue";
import Router from "vue-router";
Vue.use(Router)

const routes=[
    {path:'/',component:resolve=>(require(["../pages/index"],resolve)),meta: {x: 0, y: 0} },
    {path:'/about',component:resolve=>(require(["../pages/About/index"],resolve)),meta: {x: 0, y: 0} },
    {path:'/Case',component:resolve=>(require(["../pages/Case/index"],resolve)),meta: {x: 0, y: 0} },
    {path:'/Purchase',component:resolve=>(require(["../pages/Purchase/index"],resolve)),meta: {x: 0, y: 0} },
    {path:'/Transports',component:resolve=>(require(["../pages/Transports/index"],resolve)),meta: {x: 0, y: 0} },
    {path:'/',component:resolve=>(require(["../pages/index"],resolve)),meta: {x: 0, y: 0}},
    {path:'/huodai',component:resolve=>(require(["../pages/business/huodai/huodai"],resolve)),meta: {x: 0, y: 0}},
    {path:'/daiban',component:resolve=>(require(["../pages/business/daiban/daiban"],resolve)),meta: {x: 0, y: 0}},
    {path:'/gongyingshang',component:resolve=>(require(["../pages/business/gongyingshang/gongyingshang"],resolve)),meta: {x: 0, y: 0}},
    {path:'/login',component:resolve=>(require(["../pages/login"],resolve)),meta: {x: 0, y: 0}},
    {path:'/im/:toUserId',component:resolve=>(require(["../pages/IM/im"],resolve)),meta: {x: 0, y: 0}},
]
export default new Router({
    routes:routes,
    scrollBehavior(to, from, savedPosition) {
        return to.meta;
      }
})