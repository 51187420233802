import Vue from 'vue'
import {i18n} from './i18n/index.js'; 
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router/index.js';
Vue.use(ElementUI);
Vue.config.productionTip = false
import VueCompositionAPI from "@vue/composition-api";
import GlobalOverlayPlugin from './utils/global-overlay-plugin';
Vue.use(GlobalOverlayPlugin);
Vue.use(VueCompositionAPI);
new Vue({
  el: '#app',
  i18n,  // 添加 i18n
  router,
  render: h => h(App), // 使用 render 函数渲染 App 组件
  mounted() {
    window.isfitVue = this; // 将 Vue 实例挂载到全局
  }
}).$mount('#app');
